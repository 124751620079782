import React from 'react';
import { isEmpty } from 'lodash';
import { UpdateRecordProps } from './Challenge';
import { IRow } from '../app/AppInterfaces';
import { IResource } from './ChallengeInterfaces';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IWebeatChallengeListProps } from './WebeatChallenge';
import WebeatChallengeBuilder from './WebeatChallengeBuilder';
import WebeatChallengeSelector from './WebeatChallengeSelector';
interface IWebeatChallengeRenderProps {
  handleChangeField: ({
    id,
    value,
    type,
    multiSelectId,
    deleteImage,
  }: {
    id: string;
    value: any;
    type: string;
    multiSelectId?: any;
    deleteImage?: string;
  }) => void;
  constructResources: (resources: IRow) => IResource[];
  refreshChallengeData: () => Promise<void>;
  handleUpdateRecord: (
    e: React.MouseEvent,
    props: UpdateRecordProps,
    form: WrappedFormUtils<any>,
  ) => Promise<void>;
  handleResetChallenge: () => void;
  getMultiSelectValues: ({
    id,
    multiSelectId,
    values,
    value,
  }: {
    id: string;
    multiSelectId: string;
    values: IRow;
    value: any;
  }) => {
    [x: string]: any;
  };
}

export type IWebeatChallengeRenderListProps = IWebeatChallengeListProps &
  IWebeatChallengeRenderProps;

class WebeatChallengeRender extends React.PureComponent<
  IWebeatChallengeRenderListProps,
  {}
> {
  render() {
    const { selectedRow } = this.props;
    return isEmpty(selectedRow) ? (
      <WebeatChallengeSelector {...{ ...this.props }} />
    ) : (
      <WebeatChallengeBuilder
        {...{
          ...this.props,
        }}
      />
    );
  }
}

export default WebeatChallengeRender;
