import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ReducersState } from '../../../reducers';
import { IndexedDetail, IResourceTree } from '../../ChallengeInterfaces';
import RenderDetail from '../TreeResource/TreeDetails';

type Props = ReturnType<typeof mapStateToProps> & IResourceTree;

const WebeatCheckout = ({
  accessToken,
  editRecursiveResource,
  editResource,
  form,
  handleDeleteImages,
  resource,
  values,
}: Props): JSX.Element => {
  if (!resource.resourceDetailList?.length) return <Fragment />;

  const details: IndexedDetail[] = [];

  resource.resourceDetailList.forEach((detail, index) => {
    if (detail.status)
      details.push({
        index,
        detail,
      });
  });

  // Add here logic of Resource display items
  if (!details?.length) return <Fragment />;

  return (
    <>
      {details
        .sort(
          ({ detail: { order: orderA } }, { detail: { order: orderB } }) =>
            orderA - orderB,
        )
        .map(({ index, detail }) => {
          return RenderDetail({
            accessToken,
            detail,
            detailArrayPosition: index,
            editRecursiveResource,
            editResource,
            form,
            handleDeleteImages,
            resource,
            mainIdResource: resource.idResource,
            values,
          });
        })}
    </>
  );
};

const mapStateToProps = (state: ReducersState) => {
  return {
    accessToken: state.auth.accessToken,
  };
};
export default connect(mapStateToProps, {})(WebeatCheckout);
