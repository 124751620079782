import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
} from 'antd';
import moment, { Moment } from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../../api';
import apiPaths from '../../../apiPaths';

import {
  IModulesOpt,
  ITriggerOpt,
  OwnProps,
  TriggerValues,
} from './CreateDataReplicaButtonInterfaces';

export const CreateDataReplicaForm: FC<OwnProps> = ({
  handleModalVisibility,
  handleCancelModal,
}) => {
  // Modules
  const MODULES_OPTIONS: Array<IModulesOpt> = [
    { value: 'challenge', label: 'Challenge' },
    { value: 'grid', label: 'Menu' },
    { value: 'html_content', label: 'CMS' },
    { value: 'mail_template', label: 'Mail Templates' },
    { value: 'search_management', label: 'Search Management' },
    { value: 'static_page', label: 'Static Page' },
    { value: 'subscription_modal', label: 'Subscription Modal' },
    { value: 'parameters', label: 'Parameters' },
    { value: 'home', label: 'Home' },
    { value: 'preheader', label: 'Preheader' },
  ];

  // Triggers
  const TRIGGER_OPTIONS: Array<ITriggerOpt> = [
    { value: 'manual', description: 'Now' },
    { value: 'automatic', description: 'Schedule' },
  ];

  const { formatMessage } = useIntl();
  const [environments, setEnvironments] = useState<
    Array<{
      value: string;
      description: string;
    }>
  >([]);
  const [target, setTarget] = useState<string>();
  const [trigger, setTrigger] = useState<TriggerValues>(
    TRIGGER_OPTIONS[0].value,
  );
  const [datePicker, setDatePicker] = useState<Moment>(moment(new Date()));
  const [modules, setModules] = useState([MODULES_OPTIONS[0].value]);

  useEffect(() => {
    async function getEnvironments() {
      try {
        const response = await api.getDataCall({
          dataPath: apiPaths.WEBEAT.CALL.DATA_REPLICA_TARGETS,
          callConfig: {},
        });

        if (response?.status === 200 && response?.data) {
          setEnvironments(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    }

    getEnvironments();
  }, []);

  useEffect(() => {
    if (trigger === 'manual') {
      setDatePicker(moment(new Date()));
    }
  }, [trigger]);

  const handleModalSubmit = async () => {
    const data = {
      modules,
      target,
      trigger,
      executionDate: datePicker.toISOString(),
    };
    try {
      const response = await api.postDataCall({
        dataPath: apiPaths.WEBEAT.CALL.DATA_REPLICA_CREATE_ORDER,
        data,
        callConfig: {},
      });

      if (response?.status === 201 || response?.status === 200)
        handleModalVisibility(false);
    } catch (err) {
      console.error(err);
    }
  };

  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day');
  };

  return (
    <Row className="isdin-custom-modal__content">
      <Row className="isdin-custom-modal__title">
        {formatMessage({ id: 'data-replica.titleForm' })}
      </Row>
      <Form layout="vertical">
        <Form.Item
          label={formatMessage({
            id: 'data-replica.environment',
          })}>
          <Col xs={12} md={8}>
            <Select
              optionFilterProp="children"
              size="default"
              onChange={(value: string) => {
                setTarget(value);
              }}>
              {environments?.map((env) => (
                <Select.Option key={env.value} value={env.value}>
                  {env.description}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Form.Item>

        <Form.Item
          label={formatMessage({
            id: 'data-replica.modulesToPropagate',
          })}>
          <Checkbox.Group
            options={MODULES_OPTIONS}
            defaultValue={modules}
            onChange={(checkedValues: any) => setModules(checkedValues)}
          />
        </Form.Item>
        <Form.Item label={formatMessage({ id: 'data-replica.whenToPublish' })}>
          <Radio.Group
            onChange={({ target }) => setTrigger(target.value)}
            value={trigger}
            className="publish-radio">
            {TRIGGER_OPTIONS.map((opt) => (
              <Radio
                key={`publish_${opt.value}`}
                value={opt.value}
                className="radio-btn">
                {opt.description}
                {trigger === 'automatic' && opt.value === 'automatic' && (
                  <DatePicker
                    format="DD-MM-YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    defaultValue={datePicker}
                    size="small"
                    onChange={(date: any | null) => setDatePicker(date)}
                    className="publish-date-picker"
                    disabledDate={disabledDate}
                  />
                )}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item className="form__bottomButtons--floatRight">
          <Button
            icon="close-square-o"
            title={formatMessage({ id: 'form.edit.cancel' })}
            onClick={handleCancelModal}>
            {formatMessage({ id: 'form.edit.cancel' })}
          </Button>
        </Form.Item>
        <Form.Item className="form__bottomButtons--floatRight">
          <Button
            htmlType="submit"
            icon="save"
            title={formatMessage({ id: 'form.save' })}
            onClick={() => handleModalSubmit()}>
            {formatMessage({ id: 'form.save' })}
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};
