import { Button, Checkbox, List, Modal, Row, Icon } from 'antd';
import React, { Fragment, useEffect, useState, FC } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import {
  ChallengeTypeEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from '../../challenge/Enums';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { PIN_PlatformID_Enum } from '../../shared/enums/platform.enum';
import { ITableRenderListProps } from '../../tables/TableRender';
import { isTableProps } from '../../utils/propsUtils';
import './DuplicateButton.css';
import { ReducersState } from '../../reducers';
import { connect } from 'react-redux';
import { IRow } from '../../app/AppInterfaces';
import { loadTimezoneName } from '../../utils';
import moment from 'moment';
import { IChallenge } from '../../challenge/ChallengeInterfaces';
import _ from 'lodash';

export default function DuplicateChallengeButton<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  if (!isTableProps(parentProps)) return <Fragment />;

  const tableProps: ITableRenderListProps = parentProps;
  const { selectedRow } = tableProps.props;
  return (
    <DuplicateChallengeButtonComponet selectedRow={_.cloneDeep(selectedRow)} />
  );
}

interface OwnButtonProps {
  selectedRow: IRow;
}

interface platformInterface {
  platformCode: string;
  platformName: string;
}

type ButtonProps = ReturnType<typeof mapStateToProps> & OwnButtonProps;

const DuplicateChallengeButtonComponent: FC<ButtonProps> = ({
  selectedRow,
  platforms,
  currentPlatform,
  platformCode,
}) => {
  const intl = useIntl();
  const [visibleModalPlatforms, setVisibleModalPlatforms] = useState(false);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visibleModalResponse, setVisibleModalResponse] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    platformInterface[]
  >([]);
  const [errorPlatforms, setErrorPlatforms] = useState<platformInterface[]>([]);
  const [successPlatforms, setSuccessPlatforms] = useState<platformInterface[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [dataSource, setDataSource] = useState<platformInterface[]>([]);

  useEffect(() => {
    setLoading(true);
    if (dataSource.length === 0) {
      let enablePlatforms: string[] = Object.values(PIN_PlatformID_Enum);

      const listOfPlatforms: platformInterface[] = platforms
        .filter(
          (platform) =>
            platform.platform_code !== platformCode &&
            enablePlatforms.includes(platform.platform_code),
        )
        .map((platform) => {
          return {
            platformCode: platform.platform_code,
            platformName: platform.description,
          };
        });
      if (listOfPlatforms.length > 0) setDataSource(listOfPlatforms);
    }
    setLoading(false);
  }, [
    visibleModalConfirm,
    visibleModalPlatforms,
    dataSource,
    platformCode,
    platforms,
  ]);
  const handleEditPlatforms = (item: platformInterface) => {
    if (selectedPlatforms.includes(item)) {
      setSelectedPlatforms(
        selectedPlatforms.filter((platform) => platform !== item),
      );
    } else setSelectedPlatforms([...selectedPlatforms, item]);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks

  const closeComponent = () => {
    setSelectedPlatforms([]);
    setDataSource([]);
    setErrorPlatforms([]);
    setSuccessPlatforms([]);
    setVisibleModalPlatforms(false);
  };

  const timezoneDestination = async (destination: string): Promise<string> => {
    const platform = await api.getDataCallByCode({
      dataPath: apiPaths.AUTH.PLATFORMS,
      codeId: destination,
      callConfig: {},
    });

    return String(platform.data.timezoneName);
  };

  const destinationDate = async (destination: string): Promise<string> => {
    const timezoneDes = await timezoneDestination(destination);

    const startDateDestination = moment
      .utc(selectedRow.startDate)
      .tz(timezoneDes)
      .format('YYYY-MM-DD HH:mm:ss');

    return startDateDestination;
  };

  const addDifference = (date: string, difference: number): string | null => {
    if (!date) return null;

    const inputDate = new Date(date);

    inputDate.setTime(inputDate.getTime() + difference * 60 * 60 * 1000);

    const newDate = inputDate.toISOString();

    return newDate;
  };

  const getTimeDifference = async (destination: string): Promise<number> => {
    const currentTimezone = loadTimezoneName();
    const startDateDestination = await destinationDate(destination);

    const startDateOrigin = moment
      .utc(selectedRow.startDate)
      .tz(currentTimezone)
      .format('YYYY-MM-DD HH:mm:ss');

    const timeDifference = moment(startDateOrigin).diff(
      moment(startDateDestination),
      'hours',
    );

    return timeDifference;
  };

  const challengeNoTimeSlot = async (
    destination: string,
    challenge: IRow | IChallenge,
  ): Promise<IRow | IChallenge> => {
    const timeDifference = await getTimeDifference(destination);

    const rowNoTimeSlot = {
      ..._.cloneDeep(challenge),
      startDate: addDifference(challenge.startDate, timeDifference),
      startVisDate: addDifference(challenge.startVisDate, timeDifference),
      endDate: addDifference(challenge.endDate, timeDifference),
      endVisDate: addDifference(challenge.endVisDate, timeDifference),
      showList: false,
    };
    return rowNoTimeSlot;
  };

  const handleDuplicateChallenges = async () => {
    setVisibleModalConfirm(false);
    setVisibleModalPlatforms(false);
    closeComponent();
    setLoadingResponse(true);
    setVisibleModalResponse(true);
    for (let i = 0, l = selectedPlatforms.length; i < l; i++) {
      const platform = selectedPlatforms[i];
      try {
        let platformURL: string = '';
        let linkedChallenges: IChallenge[] = [];
        platforms?.map((platformInList) => {
          if (platformInList.platform_code === platform.platformCode)
            platformURL = platformInList.value;
        });
        let rowNoTimeSlot = await challengeNoTimeSlot(
          platform.platformCode,
          selectedRow,
        );
        if (rowNoTimeSlot) {
          const promises: Promise<any>[] = [];

          rowNoTimeSlot.resourceList.forEach((resource: any) => {
            if (
              resource &&
              resource.idResourceType.idResourceType ===
                ResourceTypeEnum.LINKED_CHALLENGES
            ) {
              resource.resourceDetailList.forEach((detail: any) => {
                if (
                  detail.idResourceTypeD.idResourceTypeD ===
                  ResourceTypeDetailEnum.LINKED_CHALLENGE_ID
                ) {
                  const promise = api.getDataCall({
                    dataPath: `${apiPaths.CALL.CHALLENGE}/${detail.value}`,
                    callConfig: {},
                  });
                  promises.push(promise);
                }
              });
            }
          });

          if (promises.length > 0) {
            const resolvedChallenges = await Promise.all(promises);
            linkedChallenges.push(
              ...resolvedChallenges.map((response) => response.data),
            );
          }
        }
        api.setAxiosBaseUrl(platformURL);

        await api.getDataCall({
          dataPath: `${apiPaths.CALL.CHALLENGE_EXIST_BY_CRITERIA}/${rowNoTimeSlot.name}?criteria=name`,
          callConfig: {},
        });

        await Promise.all(
          linkedChallenges.map(async (linkedChallenge) => {
            const linkedChallengeNoTimeSlot = await challengeNoTimeSlot(
              platform.platformCode,
              linkedChallenge,
            );

            const response = await api.postDataCall({
              dataPath: `${apiPaths.CALL.CREATE_FULL}`,
              data: {
                challenge: linkedChallengeNoTimeSlot,
                isParentChallenge: false,
              },
              callConfig: {},
            });

            if (response) {
              const fullResponse = {
                ...response,
                oldIdChallenge: linkedChallenge.idChallenge,
              };
              if (response.data.status) {
                rowNoTimeSlot.resourceList = rowNoTimeSlot.resourceList.map(
                  (resource: any) => {
                    if (
                      resource.idResourceType.idResourceType ===
                      ResourceTypeEnum.LINKED_CHALLENGES
                    ) {
                      resource.resourceDetailList =
                        resource.resourceDetailList.map((detail: any) => {
                          if (
                            detail.idResourceTypeD.idResourceTypeD ===
                              ResourceTypeDetailEnum.LINKED_CHALLENGE_ID &&
                            detail.value ===
                              fullResponse.oldIdChallenge?.toString()
                          ) {
                            detail.value = fullResponse.data.idChallenge;
                          }
                          return detail;
                        });
                    }
                    return resource;
                  },
                );
              } else {
                rowNoTimeSlot.resourceList = rowNoTimeSlot.resourceList.filter(
                  (resource: any) => {
                    if (
                      resource.idResourceType.idResourceType ===
                      ResourceTypeEnum.LINKED_CHALLENGES
                    ) {
                      return !resource.resourceDetailList.some(
                        (detail: any) => {
                          return (
                            detail.idResourceTypeD.idResourceTypeD ===
                              ResourceTypeDetailEnum.LINKED_CHALLENGE_ID &&
                            detail.value ===
                              fullResponse.oldIdChallenge?.toString()
                          );
                        },
                      );
                    }
                    return true;
                  },
                );
              }
            }
          }),
        );

        await api.postDataCall({
          dataPath: `${apiPaths.CALL.CREATE_FULL}`,
          data: { challenge: rowNoTimeSlot, isParentChallenge: true },
          callConfig: {},
        });

        setSuccessPlatforms((prevState) => prevState.concat(platform));
      } catch (err) {
        setErrorPlatforms((prevState) => prevState.concat(platform));
        console.group(err);
      } finally {
        api.setAxiosBaseUrl(currentPlatform);
      }
    }
    setLoadingResponse(false);
  };

  const showButton = [
    ChallengeTypeEnum.CAMPAIGN,
    ChallengeTypeEnum.FORMATION,
  ].includes(selectedRow?.idChallengeType?.idChallengeType);
  if (showButton) {
    return (
      <>
        <Button type="primary" onClick={() => setVisibleModalPlatforms(true)}>
          {intl.formatMessage({ id: 'button-title.duplicate-challenge' })}
        </Button>
        <Modal
          visible={visibleModalResponse}
          onCancel={() => setVisibleModalResponse(false)}
          footer={null}>
          <div className="duplicate title">
            {intl.formatMessage({
              id: 'challenge.duplicate-message-correctly',
            })}
          </div>
          <List
            loading={loadingResponse}
            itemLayout="horizontal"
            dataSource={successPlatforms}
            renderItem={(item: platformInterface) => (
              <List.Item>
                <Icon
                  className="duplicate icon"
                  type="check-circle"
                  style={{ color: 'green' }}
                />
                {item.platformName}
              </List.Item>
            )}
          />
          <div className="duplicate title">
            {intl.formatMessage({ id: 'challenge.duplicate-message-error' })}
          </div>
          <List
            loading={loadingResponse}
            itemLayout="horizontal"
            dataSource={errorPlatforms}
            renderItem={(item: platformInterface) => (
              <List.Item>
                <Icon
                  className="duplicate icon"
                  type="close-circle"
                  style={{ color: 'red' }}
                />
                {item.platformName}
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          visible={visibleModalConfirm}
          onCancel={() => setVisibleModalConfirm(false)}
          footer={null}>
          <div className="duplicate title">
            {intl.formatMessage({ id: 'challenge.duplicate-message-confirm' })}
          </div>
          <div className="duplicate advice">
            {intl.formatMessage({ id: 'challenge.duplicate-message-warning' })}
            <ul>
              <li>
                {intl.formatMessage({
                  id: 'challenge.duplicate-message-points-advice',
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: 'challenge.duplicate-message-resources-advice',
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: 'challenge.duplicate-message-show-on-list-advice',
                })}
              </li>
            </ul>
          </div>
          <Row className="duplicate container-buttons">
            <Button onClick={() => setVisibleModalConfirm(false)}>
              {intl.formatMessage({ id: 'form.edit.cancel' })}
            </Button>
            <Button
              className="duplicate.button-cancel"
              type="primary"
              onClick={() => handleDuplicateChallenges()}>
              {intl.formatMessage({ id: 'form.apply' })}
            </Button>
          </Row>
        </Modal>
        <Modal
          visible={visibleModalPlatforms}
          onCancel={() => closeComponent()}
          footer={null}>
          <div className="duplicate title">
            {intl.formatMessage({ id: 'challenge.duplicate-message' })}
          </div>
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={(item: platformInterface) => (
              <List.Item
                actions={[
                  <Checkbox onChange={() => handleEditPlatforms(item)} />,
                ]}>
                {item.platformName}
              </List.Item>
            )}
          />
          <Row className="duplicate container-button-center">
            <Button
              disabled={selectedPlatforms.length === 0}
              type="primary"
              onClick={() => setVisibleModalConfirm(true)}>
              {intl.formatMessage({ id: 'button-title.duplicate-challenge' })}
            </Button>
          </Row>
        </Modal>
      </>
    );
  }

  return <></>;
};

const mapStateToProps = (state: ReducersState) => {
  return {
    currentPlatform: state.app.currentPlatform,
    platformCode:
      state.app.platforms?.find(({ value }) => {
        return value === state.app.currentPlatform;
      })?.platform_code ?? '',
    platforms: state.app.platforms ?? [],
  };
};

const DuplicateChallengeButtonComponet = connect(
  mapStateToProps,
  {},
)(DuplicateChallengeButtonComponent);
