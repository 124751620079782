import { Select, Icon, Modal } from 'antd';
import React from 'react';
import {
  UploadType,
  uploadTypesConfiguration,
} from '../webeatCoverVideo.model';
import { useIntl } from 'react-intl';
import './TypeSelector.css';

interface Props {
  fieldName: string;
  value: string | null;
  uploadType: UploadType;
  disabled?: boolean;
  handleChange: (type: UploadType) => void;
}

export function TypeSelector({
  fieldName,
  value,
  uploadType,
  disabled,
  handleChange,
}: Props) {
  const { formatMessage } = useIntl();

  const handleSelectChange = (uploadType: UploadType) => {
    if (value) {
      Modal.confirm({
        title: formatMessage({
          id: 'webeat-cover-video-upload.type-selector.confirm-change-modal-title',
        }),
        okText: formatMessage({ id: 'pop.accept' }),
        cancelText: formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          handleChange(uploadType);
        },
        onCancel() {
          return;
        },
      });
    } else {
      handleChange(uploadType);
    }
  };

  return (
    <Select
      disabled={disabled ?? false}
      defaultValue={uploadType}
      value={uploadType}
      className="webeatCoverVideoFileTypeSelect"
      onChange={handleSelectChange}
      id={fieldName}
    >
      {Object.entries(uploadTypesConfiguration).map(
        ([uploadType, { icon }]) => {
          return (
            <Select.Option
              value={uploadType}
              className="webeatCoverVideoFileTypeSelect"
              key={uploadType}
            >
              <Icon type={icon} />
            </Select.Option>
          );
        },
      )}
    </Select>
  );
}
