import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Icon,
  Tooltip,
  Card,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import { loadTimezoneName } from '../utils';
import { usePrevious } from './ChallengeBuilder';
import { IWebeatChallengeOptions } from './WebeatChallengeConfig';
import {
  getRoleTypeValues,
  getTagValues,
  showRoleType,
} from './ChallengeUtils';
import { ChallengeRoleTypeEnum, ChallengeTypeEnum } from './Enums';
import {
  booleanFields,
  challengeDates,
} from './Enums/webeatChallengeOptionsFields.enum';

type Props = IWebeatChallengeOptions & FormComponentProps;

const WebeatOptionsForm: FC<Props> = ({
  roleTypeCombo,
  challengeTagCombo,
  editingResource,
  form: { getFieldDecorator, resetFields },
  handleChangeField,
  intl: { formatMessage },
  formHasChanged,
  values,
  UploadImage,
  UploadCoverVideo,
  isAnnualCampaign,
  isExperience,
}) => {
  const prevFormHasChanged = usePrevious(formHasChanged);
  const challengeType = values?.idChallengeType?.idChallengeType;

  const isExperienceProduct =
    values?.idChallengeType?.idChallengeType ===
    ChallengeTypeEnum.EXPERIENCE_PRODUCT;

  useEffect(() => {
    if (formHasChanged !== undefined && formHasChanged !== prevFormHasChanged)
      resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasChanged]);

  const BooleanComp = (options: {
    key: string;
    value: any;
    helper?: string;
    label?: string;
  }) => {
    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        className="challengeForm__checkbox"
        key={options.key}
      >
        <Col span={20}>
          <span style={{ lineHeight: '40px' }}>
            {options.label ?? formatMessage({ id: `challenge.${options.key}` })}
            {options.helper && (
              <Tooltip title={formatMessage({ id: options.helper })}>
                <Icon type="info-circle" style={{ marginLeft: '5px' }} />
              </Tooltip>
            )}
          </span>
        </Col>
        <Col span={4}>
          <Form.Item className="challengeForm__checkbox">
            {getFieldDecorator(options.key, {
              initialValue: options.value,
              valuePropName: 'checked',
            })(
              <Checkbox
                disabled={editingResource}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChangeField({
                    type: 'check',
                    id: options.key,
                    value: e.target.checked,
                  })
                }
                style={{ float: 'right' }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const DateComp = ({ key, value }: { key: string; value: any }) => {
    const currentTimezone = loadTimezoneName();

    // Need to set DatePicker timezone as well to avoid the component to use local machine timezone
    moment.tz.setDefault(currentTimezone);

    const dateValue = value ? moment.tz(value, currentTimezone) : undefined;

    return (
      <Row className="dateComponent" key={key}>
        <div className="dateComponent__title">
          {formatMessage({ id: `challenge.${key}` })}
        </div>
        <Row
          key={key}
          className="dateComponent__selector"
          type="flex"
          justify="space-between"
        >
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator(key, { initialValue: dateValue })(
              <DatePicker
                disabled={editingResource}
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: 'HH:mm' }}
                showToday={false}
                size="small"
                onChange={(date: any | null, dateString: string) => {
                  let newDate = null;

                  if (date) {
                    newDate = moment(dateString, 'DD-MM-YYYY HH:mm');

                    newDate = newDate.format();
                  }

                  handleChangeField({
                    type: 'date',
                    id: key,
                    value: newDate,
                  });
                }}
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
    );
  };

  return (
    <Form className="challengeConfigForm">
      {booleanFields.map((date: { key: string; helper?: string }) => {
        return BooleanComp({
          key: date.key,
          value: values ? values[date.key] : null,
          helper: date.helper,
        });
      })}
      {/* --------------------------------------------- TAGS */}
      <Row className="categoriesSelector">
        <div className="dateComponent__title">
          {formatMessage({ id: 'challenge.options.tags' })}
        </div>
        <Row className="brandSelector" type="flex" justify="space-between">
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('tags', {
              initialValue: getTagValues(values),
            })(
              <Select
                mode="multiple"
                allowClear
                showSearch
                dropdownMatchSelectWidth={false}
                disabled={false}
                onChange={(value: string) =>
                  handleChangeField({
                    type: 'selector',
                    id: 'tags',
                    value,
                    multiSelectId: 'idTag',
                  })
                }
              >
                {challengeTagCombo.map((option) => (
                  <Select.Option
                    key={option.value}
                    value={option.value.toString()}
                  >
                    {option.description}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/* --------------------------------------------- ROLES */}
      {!showRoleType(challengeType) && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.options.roles' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('role', {
                initialValue: getRoleTypeValues(values),
              })(
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  dropdownMatchSelectWidth={false}
                  onChange={(value: any) => {
                    // We check that in the multiple combo, if the role friend already exists as selected and we change it to Lover, it will automatically remove it
                    // from the list.
                    let index = value.indexOf(
                      ChallengeRoleTypeEnum.FRIEND.toString(),
                    );
                    if (
                      value.includes(ChallengeRoleTypeEnum.LOVER.toString()) &&
                      value.includes(ChallengeRoleTypeEnum.FRIEND.toString())
                    )
                      value.splice(index, 1);

                    handleChangeField({
                      type: 'selector',
                      id: 'roles',
                      value,
                      multiSelectId: 'idRoleType',
                    });
                  }}
                >
                  {roleTypeCombo.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value.toString()}
                    >
                      {option.description}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/* --------------------------------------------- SLUG */}
      <Row className="slugInput">
        <div className="dateComponent__title">
          {formatMessage({ id: 'challenge.slug' })}
        </div>
        <Row
          className="dateComponent__selector"
          type="flex"
          justify="space-between"
        >
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('slug', {
              initialValue: values?.slug,
            })(
              <Input
                disabled={editingResource}
                onChange={(e) =>
                  handleChangeField({
                    type: 'text',
                    id: 'slug',
                    value: e.target.value,
                  })
                }
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/* --------------------------------------------- FEATURED PRIORITY ORDER */}
      <Row className="slugInput">
        <div className="dateComponent__title">
          {formatMessage({
            id: values.featured
              ? 'challenge.order-priority-featured'
              : 'challenge.order-priority',
          })}
        </div>
        <Row
          className="dateComponent__selector"
          type="flex"
          justify="space-between"
        >
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('order', {
              initialValue: values?.order,
            })(
              <InputNumber
                disabled={editingResource}
                onChange={(value) =>
                  handleChangeField({
                    id: 'order',
                    type: 'number',
                    value,
                  })
                }
                min={0}
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/* --------------------------------------------- EVENT SANITY ID */}
      <Row className="slugInput">
        <div className="dateComponent__title">
          {formatMessage({ id: 'challenge.idEventSanity' })}
        </div>
        <Row
          className="dateComponent__selector"
          type="flex"
          justify="space-between"
        >
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('idEventSanity', {
              initialValue: values?.idEventSanity,
            })(
              <Input
                disabled={editingResource}
                onChange={(e) =>
                  handleChangeField({
                    type: 'text',
                    id: 'idEventSanity',
                    value: e.target.value,
                  })
                }
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/* --------------------------------------------- DATE FIELDS */}
      {challengeDates.map((date: { key: string }) => {
        return DateComp({
          key: date.key,
          value: values ? values[date.key] : null,
        });
      })}
      {isExperienceProduct && (
        <Row className="slugInput">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.featured.coverImageMobile' })}
          </div>
          <Row
            className="dateComponent__selector"
            type="flex"
            justify="space-between"
          >
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('coverImageMobile', {
                initialValue: values?.coverImageMobile,
              })(
                <Input
                  disabled={editingResource}
                  onChange={(e) =>
                    handleChangeField({
                      type: 'text',
                      id: 'coverImageMobile',
                      value: e.target.value,
                    })
                  }
                />,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {UploadImage('coverImage')}
      {!isExperienceProduct
        ? (isAnnualCampaign || isExperience) && UploadImage('coverImageMobile')
        : null}
      {!isExperience && UploadImage('endImage')}
      {!isExperience && isAnnualCampaign && UploadImage('endImageMobile')}
      <Card title={formatMessage({ id: 'challenge.featured.coverVideoGroups.desktop' })} size="small" type="inner">
        {UploadCoverVideo({
          key: 'coverVideo',
          label: formatMessage({ id: 'challenge.featured.coverVideo' }),
        })}
        {BooleanComp({
          key: 'verticalVideo',
          label: formatMessage({ id: 'challenge.verticalVideo' }),
          value: values?.['verticalVideo'] ?? null,
        })}
      </Card>
      <Card title={formatMessage({ id: 'challenge.featured.coverVideoGroups.mobile' })} size="small" type="inner" style={{ marginTop: 8 }}>
        {UploadCoverVideo({
          key: 'coverVideoMobile',
          label: formatMessage({ id: 'challenge.featured.coverVideo' }),
        })}
        {BooleanComp({
          key: 'verticalVideoMobile',
          label: formatMessage({ id: 'challenge.verticalVideo' }),
          value: values?.['verticalVideoMobile'] ?? null,
        })}
      </Card>
    </Form>
  );
};

export default Form.create<Props>({ name: 'webeat_challenge_options_form' })(
  WebeatOptionsForm,
);
