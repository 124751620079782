export const hiddenButonsWYSWYGComponent = [
  'source',
  'strikethrough',
  'italic',
  'eraser',
  'superscript',
  'subscript',
  'ul',
  'ol',
  'outdent',
  'indent',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  'image',
  'file',
  'video',
  'table',
  'align',
  'undo',
  'redo',
  'selectall',
  'cut',
  'copy',
  'paste',
  'copyformat',
  'left',
  'hr',
  'br',
  'symbol',
  'fullsize',
  'print',
  'preview',
  'find',
  'about',
  'dots',
];

export const pagesConfigurationItems = [
  'Home',
  'PDP',
  'Ecommerce Grids',
  'Register/Login',
  'LOVE ISDIN grid',
  'EDPs',
  'Specific Pages',
];

export const MESSAGE_MAX_LENGTH = 45;
export const MESSAGE_MAX_LENGTH_TWO_LINES = 74;
