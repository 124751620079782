import React, { FC, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import {
  AllRoleKey,
  GuestRoleKey,
  IPreHeaderRoles,
  PreHeaderRoleListKey,
  RegisteredRoleKey,
} from './models/preheader.models';
import { WebeatPreHeaderRolesFields } from './WebeatPreHeaderRolesFields';
import { Icon, IPreHeaderRole } from './models/preheader.models';
import './WebeatPreHeaderRoles.css';
import * as api from '../../api';
import { sortRoles } from './utils/sorted-roles';

const { Panel } = Collapse;

export const WebeatPreHeader: FC<{
  values: IPreHeaderRoles;
  parentProps: any;
  field: { key: string; type: string };
  handleChangeField: (params: {
    type: string;
    id: string;
    value: any;
    format?: string;
  }) => Promise<void> | void;
}> = ({ values, parentProps, handleChangeField, field }) => {
  const sortedRoles = sortRoles(values);
  const [roles, setRoles] = useState<IPreHeaderRole[]>(sortedRoles || []);

  const [icons, setIcons] = useState<Icon[]>([]);

  const handleChangeRole = (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => {
    const updatedRoles = roles.map((item: IPreHeaderRole) => {
      if (item.customerRole === roleKey) {
        return {
          ...role,
          ...item,
          active,
        };
      }
      return item;
    });

    setRoles(updatedRoles);
    handleChangeField({
      type: field.type,
      id: field.key,
      value: updatedRoles,
    });
  };

  useEffect(() => {
    api
      .getDataCall({
        dataPath: '/design-resources/illustration',
        callConfig: {},
      })
      .then((response) => {
        setIcons(response.data);
      });
  }, []);

  useEffect(() => {
    setRoles(sortedRoles || []);
  }, [sortedRoles]);

  return (
    <div>
      <Collapse accordion>
        {roles.map((role: IPreHeaderRole) => {
          return (
            <Panel
              header={role.customerRole}
              key={role.id}
              className={`${
                role?.active
                  ? 'webeat-preheader-role__panel--active'
                  : 'webeat-preheader-role__panel--inactive'
              } ${
                role.customerRole === RegisteredRoleKey ||
                role.customerRole === GuestRoleKey
                  ? 'webeat-preheader-role__panel--registered'
                  : role.customerRole === AllRoleKey
                  ? ''
                  : 'webeat-preheader-role__panel--registered-custom'
              }
              `}>
              <WebeatPreHeaderRolesFields
                active={!!role?.active}
                preHeaderId={values.id}
                parentProps={parentProps}
                handleChange={handleChangeRole}
                role={role}
                icons={icons}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
