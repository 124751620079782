import { Button, Col, Form, Icon, Input, Row, Select, Tooltip } from 'antd';
import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { IRow } from '../app/AppInterfaces';
import UploadComponent from '../challenge/Components/UploadComponent';
import config from '../config';
import { EditFormRenderProps } from '../forms/edit/EditFormRender';
import {
  ICompleteField,
  IEditParams,
  ISearchParams,
} from '../forms/formInterfaces';
import { SearchFormRenderProps } from '../forms/search/SearchFormRender';
import { MaterialsEnum } from '../marketingMaterials/marketingMaterials.enum';
import {
  IFileDimensions,
  IUploadActions,
  UPLOAD_ACTION_VIDEO,
  MenuEnum,
  S3BucketPathEnum,
} from '../shared';
import { comboRender } from '../contentPage/shared';
import { WebeatImageSelector } from './WebeatImageSelector';
import { isWebeatPlatform } from '../utils';
import { WebeatCollapsedListInput } from './WebeatCollapsedListInput';
import {
  VIDEO_TYPES,
  VideoTypeEnum,
  validateVideoUrl,
} from '../challenge/Components/Resource.utils';
import WYSWYGComponent from '../contentPage/components/WYSWYGComponent';
import { DisclaimerType } from './CustomFields.enum';
import moment from 'moment/moment';

const {
  PRODUCT_IMAGE,
  VIDEO_YOUTUBE,
  VIDEO_VIMEO,
  VIDEO_UPLOAD,
  PRODUCT_INFORMATION,
  OFFICE_BRANDING,
  PATIENT_OUTREACH,
} = MaterialsEnum;

interface Props {
  field: ICompleteField;
  parentProps: EditFormRenderProps | SearchFormRenderProps;
  values: IRow;
  componentId: string;
}

export default function CustomField({
  field,
  parentProps,
  values,
  componentId,
}: Props) {
  const { key } = field;
  const { handleChangeField, props } = parentProps;
  const { accessToken, form } = props;
  const { getFieldDecorator } = form;
  const { formatMessage } = useIntl();
  const {
    PRIVATE_MENU,
    PUBLIC_FOOTER_PARENT,
    PUBLIC_FOOTER_CHILD,
    PRIVATE_FOOTER_PARENT,
    PRIVATE_FOOTER_CHILD,
  } = MenuEnum;
  const customTooltip = (messageId: string, iconType: string) => {
    return (
      <Tooltip
        className="fieldTooltip"
        title={formatMessage({
          id: messageId,
        })}
      >
        <Icon type={iconType} />
      </Tooltip>
    );
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.type, values?.absolute]);

  const UploadFile = ({
    key,
    format,
    title,
    fileDimensions,
    uploadActions,
    notRemovable,
    S3BucketPath,
  }: {
    key: string;
    format: 'video' | 'image' | 'pdf';
    title: string;
    fileDimensions?: IFileDimensions;
    uploadActions?: IUploadActions[];
    notRemovable?: boolean;
    S3BucketPath?: S3BucketPathEnum;
  }) => {
    const isEditParentProps = (
      props: IEditParams | ISearchParams,
    ): props is IEditParams => {
      return (props as IEditParams).primaryKey !== undefined;
    };

    return (
      <Col xs={24} md={12}>
        <UploadComponent
          {...{
            format,
            setValue: (value) =>
              handleChangeField({ id: key, type: 'text', value }),
            token: accessToken,
            value: values[key],
            fileDimensions,
            uploadActions,
            handleChangeField: notRemovable ? null : handleChangeField,
            fieldName: key,
            primaryEntityId: isEditParentProps(parentProps.props.params)
              ? values[parentProps.props.params.primaryKey]
              : '',
            module: field.module || '',
            S3BucketPath,
          }}
        >
          <Button style={{ width: '100%' }} icon="upload">
            {window.innerWidth < config.BREAKPOINTS.MD ||
              (window.innerWidth > config.BREAKPOINTS.LG && title)}
          </Button>
        </UploadComponent>
      </Col>
    );
  };

  const SurveyImageUploader = (field: ICompleteField) => {
    const { key, title } = field;
    const isEditParentProps = (
      props: IEditParams | ISearchParams,
    ): props is IEditParams => {
      return (props as IEditParams).primaryKey !== undefined;
    };

    return !isWebeatPlatform() ||
      (isWebeatPlatform() &&
        isEditParentProps(parentProps.props.params) &&
        values[parentProps.props.params.primaryKey]) ? (
      <Row className="isdin-custom-upload">
        <div className="isdin-custom-upload__title" title={field.title}>
          {field.title}
        </div>
        {UploadFile({
          key,
          format: 'image',
          title,
          fileDimensions: {
            width: config.CHALLENGE.COVER_IMAGE_WIDTH,
            height: config.CHALLENGE.COVER_IMAGE_HEIGHT,
          },
        })}
      </Row>
    ) : null;
  };

  function handleChangeVideoInput(
    e: ChangeEvent<HTMLInputElement>,
    fieldId: string,
    videoType: VideoTypeEnum,
  ) {
    const value = e.target.value.trim();

    const videoId = validateVideoUrl(value, videoType);

    handleChangeField({
      type: 'text',
      id: fieldId,
      value: videoId.value,
    });
  }

  const MarketingMaterialFields = (field: ICompleteField, values: IRow) => {
    const { type } = values;

    if (!type) return;

    const renderVideoFields = (videoTypeId: MaterialsEnum) => {
      const videoType: VideoTypeEnum | undefined = VIDEO_TYPES[videoTypeId];

      if (videoType !== undefined) {
        return (
          <Form.Item
            label={formatMessage({
              id: `custom-field.${VIDEO_TYPES[videoTypeId]}-video-id`,
            })}
          >
            <Row>
              {UploadFile({
                key: 'image',
                format: 'image',
                title: formatMessage({
                  id: 'custom-field.upload-image',
                }),
                S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
              })}
            </Row>
            <div className="fieldTooltip-row">
              <Col xs={24} md={12}>
                {getFieldDecorator('content', {
                  initialValue: values.content,
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'update.required' }),
                    },
                    {
                      validator: (_: any, value: any) => {
                        return validateVideoUrl(value, videoType).isValid;
                      },
                      message: formatMessage({
                        id: `validation.${VIDEO_TYPES[videoTypeId]}-url.field`,
                      }),
                    },
                  ],
                })(
                  <Input
                    onChange={(e) =>
                      handleChangeVideoInput(e, 'content', videoType)
                    }
                  />,
                )}
              </Col>
              {customTooltip(
                `upload.video.${VIDEO_TYPES[videoTypeId]}ID.tooltip`,
                'question-circle-o',
              )}
            </div>
          </Form.Item>
        );
      }
    };

    switch (parseInt(type)) {
      case PATIENT_OUTREACH:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
            {UploadFile({
              key: 'content',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-file' }),
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
          </Row>
        );
      case PRODUCT_INFORMATION:
      case OFFICE_BRANDING:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
            {UploadFile({
              key: 'content',
              format: 'pdf',
              title: formatMessage({ id: 'custom-field.upload-file' }),
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
          </Row>
        );
      case PRODUCT_IMAGE:
        return UploadFile({
          key: 'content',
          format: 'image',
          title: formatMessage({ id: 'custom-field.upload-image' }),
          notRemovable: true,
          S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
        });
      case VIDEO_YOUTUBE:
        return renderVideoFields(VIDEO_YOUTUBE);
      case VIDEO_VIMEO:
        return renderVideoFields(VIDEO_VIMEO);
      case VIDEO_UPLOAD:
        return (
          <Row gutter={[24, 0]}>
            {UploadFile({
              key: 'image',
              format: 'image',
              title: formatMessage({ id: 'custom-field.upload-image' }),
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
            {UploadFile({
              key: 'content',
              format: 'video',
              title: formatMessage({ id: 'custom-field.upload-video' }),
              uploadActions: [UPLOAD_ACTION_VIDEO],
              S3BucketPath: S3BucketPathEnum.MARKETING_MATERIALS,
            })}
          </Row>
        );
      default:
        return;
    }
  };

  const ScientificMaterialCardImage = () => {
    return (
      <Form.Item
        label={formatMessage({
          id: 'custom-field.scientific-material.card-image',
        })}
      >
        <Row gutter={[24, 0]}>
          {UploadFile({
            key: 'imageUrl',
            format: 'image' as 'image',
            title: formatMessage({
              id: 'custom-field.upload-image',
            }),
            S3BucketPath: S3BucketPathEnum.SCIENTIFIC_MATERIALS,
          })}
        </Row>
      </Form.Item>
    );
  };

  const ScientificMaterialFields = (fields: ICompleteField, value: IRow) => {
    const { videoType } = value;

    switch (parseInt(videoType)) {
      case VIDEO_YOUTUBE:
        return (
          <>
            <Form.Item
              label={formatMessage({
                id: 'custom-field.youtube-video-id',
              })}
            >
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12}>
                  <div className="fieldTooltip-row">
                    {getFieldDecorator('videoUrl', {
                      initialValue: values.videoUrl,
                      rules: [
                        {
                          validator: (
                            _: any,
                            value: any,
                            callback: Function,
                          ) => {
                            if (
                              !value ||
                              validateVideoUrl(value, VideoTypeEnum.YOUTUBE)
                                .isValid
                            ) {
                              callback();
                            } else {
                              callback(
                                formatMessage({
                                  id: 'validation.youtube-url.field',
                                }),
                              );
                            }
                          },
                        },
                      ],
                    })(
                      <Input
                        onChange={(e) =>
                          handleChangeVideoInput(
                            e,
                            'videoUrl',
                            VideoTypeEnum.YOUTUBE,
                          )
                        }
                      />,
                    )}
                    {customTooltip(
                      'upload.video.youtubeID.tooltip',
                      'question-circle-o',
                    )}
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Row className="isdin-custom-upload">
              <div className="isdin-custom-upload__title" title={field.title}>
                {field.title}
              </div>
              {UploadFile({
                key: 'summaryUrl',
                format: 'pdf',
                title: formatMessage({ id: 'custom-field.upload-file' }),
                S3BucketPath: S3BucketPathEnum.SCIENTIFIC_MATERIALS,
              })}
            </Row>
          </>
        );
      case VIDEO_VIMEO:
        return (
          <>
            <Form.Item
              label={formatMessage({
                id: 'custom-field.vimeo-video-id',
              })}
            >
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12}>
                  <div className="fieldTooltip-row">
                    {getFieldDecorator('videoUrl', {
                      initialValue: values.videoUrl,
                      rules: [
                        {
                          validator: (
                            _: any,
                            value: any,
                            callback: Function,
                          ) => {
                            if (
                              !value ||
                              validateVideoUrl(value, VideoTypeEnum.VIMEO)
                                .isValid
                            ) {
                              callback();
                            } else {
                              callback(
                                formatMessage({
                                  id: 'validation.vimeo-url.field',
                                }),
                              );
                            }
                          },
                        },
                      ],
                    })(
                      <Input
                        onChange={(e) =>
                          handleChangeVideoInput(
                            e,
                            'videoUrl',
                            VideoTypeEnum.VIMEO,
                          )
                        }
                      />,
                    )}
                    {customTooltip(
                      'upload.video.vimeoID.tooltip',
                      'question-circle-o',
                    )}
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Row className="isdin-custom-upload">
              <div className="isdin-custom-upload__title" title={field.title}>
                {field.title}
              </div>
              {UploadFile({
                key: 'summaryUrl',
                format: 'pdf',
                title: formatMessage({ id: 'custom-field.upload-file' }),
                S3BucketPath: S3BucketPathEnum.SCIENTIFIC_MATERIALS,
              })}
            </Row>
          </>
        );
    }
  };

  const MenuCombo = (comboProps: any) => {
    const initialValue = values && values[key] ? values[key].toString() : '';

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item
          label={formatMessage({
            id: 'custom-field.' + key,
          })}
        >
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              // allowClear={!field.hasOwnProperty('initialValue')}
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={formatMessage({
                id: `userMenu.${key}.placeholder`,
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: key,
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}
            >
              {comboRender(
                comboProps?.comboData,
                props?.combos,
                'menumenuEdit',
              )}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const MenuRoute = (field: ICompleteField, values: IRow) => {
    if (
      ![PRIVATE_MENU, PUBLIC_FOOTER_CHILD, PRIVATE_FOOTER_CHILD].includes(
        values?.type?.toString(),
      )
    )
      return <></>;

    let initialValue = values.route;
    const comboData = { comboId: 'menuPages', key: 'menuPages' };

    return values?.absolute?.toString() === 'true' ? (
      <Form.Item
        label={formatMessage({
          id: 'custom-field.route',
        })}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            {getFieldDecorator('route', {
              initialValue: initialValue,
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'update.required' }),
                },
              ],
            })(
              <Input
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeField({
                    type: 'text',
                    id: 'route',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Col>
        </Row>
      </Form.Item>
    ) : (
      <Row gutter={[24, 0]}>
        <Col xs={12}>
          <Form.Item
            label={formatMessage({
              id: 'custom-field.' + key,
            })}
          >
            <Input value={values?.route} disabled={true} />
          </Form.Item>
        </Col>
        <MenuCombo comboData={comboData} fieldSizes={{ col: { width: 12 } }} />
      </Row>
    );
  };

  const MenuOrder = (field: ICompleteField, values: IRow) => {
    let initialValue = values.order;
    if (!values?.type) return <></>;
    const comboDataPrivate = {
      comboId: 'privateFooterOrder',
      key: 'privateFooterOrder',
    };
    const comboDataPublic = {
      comboId: 'publicFooterOrder',
      key: 'publicFooterOrder',
    };

    switch (values?.type?.toString()) {
      case PUBLIC_FOOTER_PARENT:
        return (
          <Row gutter={[24, 0]}>
            <MenuCombo comboData={comboDataPublic} />
          </Row>
        );
      case PRIVATE_FOOTER_PARENT:
        return (
          <Row gutter={[24, 0]}>
            <MenuCombo comboData={comboDataPrivate} />
          </Row>
        );
      default:
        return (
          <Form.Item
            label={formatMessage({
              id: 'custom-field.order',
            })}
          >
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                {getFieldDecorator('order', {
                  initialValue: initialValue,
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'update.required' }),
                    },
                  ],
                })(
                  <Input
                    type="number"
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeField({
                        type: 'number',
                        id: 'order',
                        value: e.target.value,
                      });
                    }}
                  />,
                )}
              </Col>
            </Row>
          </Form.Item>
        );
    }
  };

  const WebeatMenuOrder = (field: ICompleteField, values: IRow) => {
    let initialValue = values.order;
    return (
      <Form.Item
        label={
          field.tooltip ? (
            <span>
              {formatMessage({
                id: 'custom-field.order',
              })}
              <Tooltip className="fieldTooltip" title={field.tooltip}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          ) : (
            formatMessage({
              id: 'custom-field.order',
            })
          )
        }
      >
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            {getFieldDecorator('order', {
              initialValue: initialValue,
              rules: [
                {
                  required: false,
                  message: formatMessage({ id: 'update.required' }),
                },
                {
                  validator: (rule, value, callback) => {
                    if (!!value && value < 0) {
                      callback(
                        formatMessage({
                          id: 'custom-field.order-optional.validator',
                        }),
                      );
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Input
                type="number"
                disabled={!values?.type}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeField({
                    type: 'number',
                    id: 'order',
                    value: e.target.value,
                  });
                }}
              />,
            )}
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const MenuWebeatCombo = (comboProps: any) => {
    const initialValue =
      values && values['idGrid.category']
        ? values['idGrid.category'].toString()
        : values['route']
          ? values['route']
          : '';

    const filterOptions = (combos: any) => {
      const options =
        combos.combos[componentId][comboProps.comboData.key][
          comboProps.comboData.comboId
        ].data;
      return options
        ? options.filter((o: any) => o.pageType === values['pageType'])
        : [];
    };

    const filteredOptions: any = {
      combos: {
        webeatMenuEdit: {},
        webeatMenuChildEdit: {},
      },
    };
    filteredOptions.combos[componentId][comboProps.comboData.key] = {};
    filteredOptions.combos[componentId][comboProps.comboData.key][
      comboProps.comboData.comboId
    ] = { data: filterOptions(props?.combos) };

    const updateValue = (value: any, data: any) => {
      handleChangeField({
        type: 'combo',
        id: 'routeSelector',
        value: value >= 0 || typeof value === 'string' ? data.key : null,
      });
      if (
        values['pageType'] === 'STATIC_PAGE' ||
        values['pageType'] === 'FRONT_PAGE'
      ) {
        handleChangeField({
          type: 'text',
          id: 'route',
          value: value >= 0 || typeof value === 'string' ? data.key : null,
        });
      }
    };

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item
          label={formatMessage({
            id: 'custom-field.category',
          })}
        >
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: values['pageType'] !== 'GROUPER',
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              // allowClear={!field.hasOwnProperty('initialValue')}
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={formatMessage({
                id: `userMenu.${key}.placeholder`,
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => updateValue(value, data)}
            >
              {comboRender(comboProps?.comboData, filteredOptions, componentId)}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const MenuWebeatRoute = (field: ICompleteField, values: IRow) => {
    let initialValue = values.route;
    const comboData = {
      comboId: 'webeatMenuPages',
      key: 'webeatMenuPages',
    };

    switch (values?.pageType) {
      case 'GROUPER':
        return <></>;
      case 'ABSOLUTE_ROUTE':
        return (
          <Form.Item
            label={formatMessage({
              id: 'custom-field.route',
            })}
          >
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                {getFieldDecorator('route', {
                  initialValue: initialValue,
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'update.required' }),
                    },
                  ],
                })(
                  <Input
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeField({
                        type: 'text',
                        id: 'route',
                        value: e.target.value,
                      });
                    }}
                  />,
                )}
              </Col>
            </Row>
          </Form.Item>
        );
      case 'GRID':
        return (
          <Row gutter={[24, 0]}>
            <MenuWebeatCombo
              comboData={comboData}
              fieldSizes={{ col: { width: 12 } }}
            />
            <Col xs={12}>
              <Form.Item
                label={formatMessage({
                  id: 'custom-field.url',
                })}
              >
                {getFieldDecorator('route', {
                  initialValue: initialValue,
                })(
                  <Input
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeField({
                        type: 'text',
                        id: 'route',
                        value: e.target.value,
                      });
                    }}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
        );
      default:
        return (
          <Row gutter={[24, 0]}>
            <MenuWebeatCombo
              comboData={comboData}
              fieldSizes={{ col: { width: 12 } }}
            />
            <Col xs={12}>
              <Form.Item
                label={formatMessage({
                  id: 'custom-field.' + key,
                })}
              >
                <Input value={values?.route} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        );
    }
  };

  const WebeatRestrictionCombo = (comboProps: any) => {
    const initialValue = values && values['value'] ? values['value'] : '';

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item
          label={formatMessage({
            id: 'webeatRestrictionsPage.state',
          })}
        >
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={formatMessage({
                id: 'webeatRestrictionsPage.state',
              })}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: 'restrictionStateSelector',
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}
            >
              {comboRender(comboProps?.comboData, props?.combos, componentId)}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const WebeatRestrictionComboListOfValues = (comboProps: any) => {
    const initialValue = values && values['value'] ? values['value'] : '';
    console.log('comboProps', { initialValue }, values);

    return (
      <Col xs={comboProps?.fieldSizes?.col?.width || 24}>
        <Form.Item label="List Of Values">
          {getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'update.required' }),
              },
            ],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={false}
              allowClear
              size="default"
              placeholder={'List Of Values'}
              notFoundContent={formatMessage({
                id: 'combo.data.notfound',
              })}
              filterOption={(input: any, option: any) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any, data: any) => {
                handleChangeField({
                  type: 'combo',
                  id: 'restrictionListOfValueSelector',
                  value:
                    value >= 0 || typeof value === 'string' ? data.key : null,
                });
              }}
            >
              {comboRender(comboProps?.comboData, props?.combos, componentId)}
            </Select>,
          )}
        </Form.Item>
      </Col>
    );
  };

  const WebeatRestriction = (field: ICompleteField, values: IRow) => {
    const initialValue = values && values['value'] ? values['value'] : '';
    const comboData = {
      comboId: 'webeatRestrictionStates',
      key: 'webeatRestrictionStates',
    };
    const comboDataListOfValues = {
      comboId: 'webeatRestrictionListOfValues',
      key: 'webeatRestrictionListOfValues',
    };
    if (values?.entityFilterType === 'POSTAL_CODE') {
      return (
        <Form.Item
          label={formatMessage({
            id: formatMessage({ id: 'webeatRestrictionsPage.postalCode' }),
          })}
        >
          <Row gutter={[24, 0]}>
            <Col xs={12}>
              {getFieldDecorator('value', {
                initialValue: initialValue,
                rules: [
                  {
                    required: true,
                    message: formatMessage({ id: 'update.required' }),
                  },
                ],
              })(
                <Input
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeField({
                      type: 'text',
                      id: 'value',
                      value: e.target.value,
                    });
                  }}
                />,
              )}
            </Col>
          </Row>
        </Form.Item>
      );
    } else if (values?.entityFilterType === 'FRONT_PAGES') {
      return (
        <Row gutter={[24, 0]}>
          <WebeatRestrictionComboListOfValues
            comboData={comboDataListOfValues}
            fieldSizes={{ col: { width: 12 } }}
          />
        </Row>
      );
    } else {
      return (
        <Row gutter={[24, 0]}>
          <WebeatRestrictionCombo
            comboData={comboData}
            fieldSizes={{ col: { width: 12 } }}
          />
        </Row>
      );
    }
  };

  const DisclaimerFields = (values: IRow) => {
    const { message } = values;
    switch (values.disclaimerType) {
      case DisclaimerType.COMMERCIAL:
        return (
          <WYSWYGComponent
            title={formatMessage({ id: 'disclaimer.message' })}
            value={message}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeField({
                type: 'customField',
                id: 'message',
                value: e.target.value,
              });
            }}
          ></WYSWYGComponent>
        );
      case DisclaimerType.OPERATIONAL:
        return;
    }
  };

  const IZoomCodeField = (field: ICompleteField, value: IRow) => {
    const preIZoomCode = moment(value?.createDate || new Date()).format(
      'YYYY_MM_',
    );
    const postIZoomCode = `_scientific-materials`;
    const iZoomPosition = 2;
    const tailSplit = 3;
    return (
      <Form.Item label={formatMessage({ id: 'challenge.code' })}>
        {getFieldDecorator('code', {
          initialValue: values['code']
            ? values['code'].split('_', tailSplit)[iZoomPosition]
            : '',
          rules: [
            {
              required: true,
              message: formatMessage({ id: 'update.required' }),
            },
          ],
        })(
          <Input
            style={{ clear: 'both' }}
            size="large"
            addonAfter={postIZoomCode}
            addonBefore={preIZoomCode}
            placeholder={formatMessage({
              id: 'challenge.code',
            })}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const iZoomValue = e.target.value.replace(/_/gm, '-');
              return handleChangeField({
                id: 'code',
                type: 'text',
                value: `${preIZoomCode}${iZoomValue}${postIZoomCode}`,
              });
            }}
          />,
        )}
      </Form.Item>
    );
  };

  if (field.objectArrayFields) {
    return WebeatCollapsedListInput(field, parentProps);
  }

  if (key.includes('gridBanners') && key.includes('imageUrl')) {
    return WebeatImageSelector(field, parentProps);
  }

  switch (key) {
    case 'uploads':
      return MarketingMaterialFields(field, values);
    case 'scientificCardImage':
      return ScientificMaterialCardImage();
    case 'scientificUploads':
      return ScientificMaterialFields(field, values);
    case 'iZoomCode':
      return IZoomCodeField(field, values);
    case 'image':
      return SurveyImageUploader(field);
    case 'route':
      return MenuRoute(field, values);
    case 'webeat-route':
      return MenuWebeatRoute(field, values);
    case 'order':
      return MenuOrder(field, values);
    case 'order-optional':
      return WebeatMenuOrder(field, values);
    case `banner${field.index}Type`:
      return WebeatImageSelector(field, parentProps);
    case `idGrid.banner${field.index}Type`:
      return WebeatImageSelector(field, parentProps);
    case 'webeat-restriction':
      return WebeatRestriction(field, values);
    case 'disclaimerFields':
      return DisclaimerFields(values);
    default:
      return;
  }
}
