import React, { useState, ReactElement } from 'react';
import { UploadType, validateVideoUrl } from '../webeatCoverVideo.model';
import { Button, Modal } from 'antd';
import config from '../../../../config';
import { DirectVideoUrlPreview } from './components/DirectVideoUrlPreview';
import { YouTubeVideoPreview } from './components/YouTubeVideoPreview';
import './PreviewButton.css';

interface Props {
  url: string | null;
  uploadType: UploadType | null;
  disabled?: boolean;
}

export default function PreviewButton({ url, uploadType, disabled }: Props) {
  const storageRootUrl = config.BUCKET.WEBEAT_BUCKET;
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  if (
    url === null ||
    url === '' ||
    uploadType === null ||
    !validateVideoUrl(url, uploadType)
  ) {
    return null;
  }

  let previewComponent: ReactElement | null = null;

  switch (uploadType) {
    case UploadType.S3:
      previewComponent = (
        <DirectVideoUrlPreview url={`${storageRootUrl}${url}`} />
      );
      break;

    case UploadType.GenericUrl:
      const youTubeId = getYouTubeId(url);
      if (youTubeId !== null) {
        previewComponent = <YouTubeVideoPreview id={youTubeId} />;
      } else {
        previewComponent = <DirectVideoUrlPreview url={url} />;
      }
      break;
  }

  if (previewComponent === null) {
    return null;
  }

  return (
    <>
      <Button
        className="webeatCoverVideoPreviewButton"
        onClick={() => setShowPreviewModal(true)}
        disabled={disabled ?? false}
        icon="eye"
      />

      <Modal
        visible={showPreviewModal}
        centered
        onCancel={() => setShowPreviewModal(false)}
        footer={false}
        className="webeatCoverVideoPreviewModal"
        destroyOnClose
      >
        {previewComponent}
      </Modal>
    </>
  );
}

const YOUTUBE_REGEXES = [
  /youtu.be\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/,
  /youtube\.com\/watch\?(?:.*&)?v=([a-zA-Z0-9_-]{11})(?:&.*)?$/,
  /youtube\.com\/(?:embed|live|shorts)\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/,
];

/**
 * Given a url of type {@link UploadType.GenericUrl}, returns the YouTube video ID
 * if the URL is a YouTube URL, or `undefined` otherwise.
 *
 * NOTE: This is just a best-effort attempt to detect YouTube URLs, to allow users
 * to preview them. This doesn't ensure that the URL will or will not be correctly
 * rendered by the front-office or the mobile app.
 */
function getYouTubeId(url: string): string | null {
  for (const pattern of YOUTUBE_REGEXES) {
    const match = url.match(pattern);
    if (match) {
      const [, videoId] = match;
      return videoId;
    }
  }

  return null;
}
