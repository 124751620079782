import React from 'react';
import { Row } from 'antd';

interface Props {
  url: string;
}

export function DirectVideoUrlPreview({ url }: Props) {
  return (
    <Row className="webeatCoverVideoPreviewModal__preview">
      <video src={url} width="95%" height="auto" controls />
    </Row>
  );
}
