import React from 'react';
import { Row } from 'antd';
import './YouTubeVideoPreview.css';

interface Props {
  id: string;
}

export function YouTubeVideoPreview({ id }: Props) {
  return (
    <Row className="webeatCoverVideoPreviewModal__preview webeatCoverVideoPreviewModal__preview--youtube-video">
      <iframe
        title="youtube_url"
        width="95%"
        height="auto"
        src={`https://www.youtube.com/embed/${id}`}
        frameBorder="0"
        allow="autoplay; encrypted-media; picture-in-picture"
        allowFullScreen
      />
    </Row>
  );
}
