import { Tooltip, Icon, Input } from 'antd';
import React from 'react';
import { validateVideoUrl, UploadType } from '../../webeatCoverVideo.model';
import { useIntl } from 'react-intl';
import './GenericUrlInput.css';

interface Props {
  value: string | null;
  disabled?: boolean;
  handleChange: (value: string) => void;
}

export default function GenericUrlInput({
  value,
  disabled,
  handleChange,
}: Props) {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="webeatCoverVideoTooltip">
        <Tooltip
          title={formatMessage({
            id: 'webeat-cover-video-upload.generic-url.tooltip',
          })}
        >
          <Icon type="question-circle-o" />
        </Tooltip>
      </div>
      <div className="webeatCoverVideoGenericUrlInput">
        <Input
          disabled={disabled ?? false}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          allowClear
          value={value ?? ''}
          defaultValue={
            value && validateVideoUrl(value, UploadType.GenericUrl) ? value : ''
          }
        />
      </div>
    </>
  );
}
