import React, { useState, PropsWithChildren } from 'react';
import {
  loadItem,
  PLATFORM_CODE_KEY,
  getPlatformBaseUrl,
  saveState,
} from '../../../../../utils';
import { useIntl } from 'react-intl';
import { getValidMimeTypes, UploadType } from '../../webeatCoverVideo.model';
import { notification } from 'antd';
import * as api from '../../../../../api';
import store from '../../../../../store';
import { authTypes } from '../../../../../auth/authTypes';
import config from '../../../../../config';
import UploadingModal from './UploadingModal';
import Upload from 'rc-upload';
import './S3Input.css';

const MAX_SIZE_IN_BYTES = 5 * 1000 * 1000;

interface Props {
  value: string | null;
  disabled?: boolean;
  handleChange: (value: string | null) => void;
  challengeId: string | number;
  token: string;
}

export default function S3Input({
  value,
  disabled,
  handleChange,
  challengeId,
  token,
  children,
}: PropsWithChildren<Props>) {
  const apiRootUrl = getPlatformBaseUrl();
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  const { formatMessage } = useIntl();

  const [uploading, setUploading] = useState(false);

  const validFormats = getValidMimeTypes(UploadType.S3);

  const handleRemove = () => {
    handleChange(null);
  };

  const validateFile = (file: File) => {
    const { type, size } = file;

    if (size > MAX_SIZE_IN_BYTES) {
      return {
        message: formatMessage({
          id: 'webeat-cover-video-upload.s3-input.error',
        }),
        description: formatMessage(
          {
            id: 'webeat-cover-video-upload.s3-input.error-descriptions.size-exceeded',
          },
          {
            sizeLimit: `${(MAX_SIZE_IN_BYTES / (1000 * 1000)).toFixed(1)} kB`,
          },
        ),
      };
    } else if (validFormats && !validFormats.includes(type)) {
      return {
        message: formatMessage({
          id: 'webeat-cover-video-upload.s3-input.error',
        }),
        description: formatMessage({
          id: 'webeat-cover-video-upload.s3-input.error-descriptions.wrong-format',
        }),
      };
    } else {
      return undefined;
    }
  };

  const handleCheckFile = (file: File) => {
    const error = validateFile(file);
    if (error) {
      notification.error({
        message:
          error.message ||
          formatMessage({
            id: 'webeat-cover-video-upload.s3-input.error-descriptions.unexpected',
          }),
        description: error.description,
      });
    }
    return error ? false : true;
  };

  const handleSuccess = async (response: {
    [key: string]: string;
  }): Promise<void> => {
    setUploading(false);
    handleChange(response.url ? response.url.trim() : null);
    notification.success({
      message: formatMessage({
        id: 'webeat-cover-video-upload.s3-input.success',
      }),
      duration: 3,
    });
  };

  const handleError = (_err: Error, response: any) => {
    if (response.code === 401) {
      api
        .refreshCall()
        .then((response: any) => {
          const { accessToken, refreshToken } = response.data;
          const auth = {
            accessToken: `Bearer ${accessToken}`,
            refreshToken: refreshToken,
            isAuthorized: true,
          };
          saveState({ auth });
          store.dispatch({
            type: authTypes.SET_TOKENS,
            payload: {
              accessToken: `Bearer ${accessToken}`,
              refreshToken: refreshToken,
            },
          });
          notification.warning({
            message: formatMessage({
              id: 'webeat-cover-video-upload.s3-input.session-refreshed',
            }),
          });
        })
        .catch((_err: any) => {
          notification.warning({
            message: formatMessage({ id: 'server.session.expired' }),
          });
          api.forceLogout();
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const uploadPath = `${apiRootUrl}upload/${challengeId}/MEDIA?module=challenge`;

  return (
    <>
      <div className="webeatCoverVideoUploadS3Input">
        <Upload
          name="upload"
          disabled={disabled ?? false}
          action={uploadPath}
          headers={{
            Authorization: token,
            platform: config.APP.PLATFORM,
            'x-isdin-platform': platformCode || config.APP.PLATFORM_CODE,
          }}
          beforeUpload={handleCheckFile}
          onError={handleError}
          onStart={() => setUploading(true)}
          onSuccess={handleSuccess}
          handleRemove={handleRemove}
          fileList={value ? [{ id: value }] : []}
        >
          {children}
        </Upload>
      </div>
      <UploadingModal uploading={uploading} />
    </>
  );
}
