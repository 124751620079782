import {
  IPreHeaderRoles,
  PreHeaderRoleListKey,
} from '../models/preheader.models';

const orderRoles: PreHeaderRoleListKey[] = [
  'All',
  'Guest',
  'Registered',
  'RegisteredLover',
  'RegisteredFriend',
];

export const sortRoles = (values: IPreHeaderRoles) => {
  return values.preHeaderRoles?.sort((a, b) => {
    return (
      orderRoles.indexOf(a.customerRole) - orderRoles.indexOf(b.customerRole)
    );
  });
};
