import React, { FC, Fragment, useState } from 'react';
import {
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from '../../models/preheader.models';

import { Icon, IPreHeaderRole } from '../../models/preheader.models';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import FormItem from 'antd/lib/form/FormItem';
import WYSWYGComponent from '../../../../contentPage/components/WYSWYGComponent';
import { hiddenButonsWYSWYGComponent } from '../../constants';
import { PreHeaderSVGSelector } from '../preHeaderSVGSelector/PreHeaderSVGSelector';
// import PreHeaderPagesConfiguration from '../preHeaderPagesConfiguration/PreHeaderPagesConfiguration';
import { messageErrorLength } from './validate-fields';
import { Col, Collapse, Modal, Row, Switch } from 'antd';
import { useIntl } from 'react-intl';

export const PreHeaderMessageFields: FC<{
  messages: IPreHeaderRoleMessage[];
  preHeaderId?: string;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
  role: IPreHeaderRole;
  icons: Icon[];
  active?: boolean;
}> = ({ handleChange, role, icons, messages }) => {
  const sortedMessages = messages.sort((a, b) => a.order - b.order);

  const { formatMessage } = useIntl();
  const { Panel } = Collapse;
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const [orderedMessages, setOrderedMessages] =
    useState<IPreHeaderRoleMessage[]>(sortedMessages);

  const onDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (dropIndex: number) => {
    if (draggedItemIndex === null) return;

    const updatedItems = [...orderedMessages];

    const [draggedItem] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);
    updatedItems.forEach((item, index) => {
      if (item) {
        item.order = index + 1;
      }
    });

    setOrderedMessages(updatedItems);
    handleChange(role.customerRole, role.active, {
      ...role,
      preHeaderMessages: updatedItems,
    });
    setDraggedItemIndex(null);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleChangeActiveMessage = (
    message: IPreHeaderRoleMessage,
    checked: boolean,
    event: MouseEvent,
  ) => {
    event.stopPropagation();
    const activeMessagesCount = (orderedMessages ?? []).filter(
      (m: IPreHeaderRoleMessage) => m.active,
    ).length;

    console.log("Role: ", role.active);
    console.log("Checked: ", checked);
    console.log("Active Messages Count: ", activeMessagesCount);  
    
    if (role.active && !checked && activeMessagesCount === 1) {
      setIsModalVisible(true);
      return;
    }

    handleChange(role.customerRole, role.active, {
      ...role,
      preHeaderMessages: (orderedMessages ?? []).map(
        (m: IPreHeaderRoleMessage) => {
          if (m.id === message.id) {
            message.active = checked;
          }
          return m;
        },
      ),
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Title level={2}>Message List</Title>
      <br />
      {orderedMessages.map((message: IPreHeaderRoleMessage, i: number) => (
        <div
          key={i}
          draggable
          onDragStart={() => onDragStart(i)}
          onDragOver={(e) => onDragOver(e)}
          onDrop={(e) => onDrop(i)}>
          <Collapse accordion>
            <Panel
              header={
                <Row>
                  <Col span={23}>{message.id || 'New Message'}</Col>
                  <Col span={1}>
                    <Switch
                      size="small"
                      checked={message.active}
                      onChange={(checked, event) => {
                        handleChangeActiveMessage(message, checked, event);
                      }}
                    />
                  </Col>
                </Row>
              }
              key={message.id}
              className={
                message.active
                  ? 'webeat-preheader-role__message--active'
                  : 'webeat-preheader-role__message--inactive'
              }>
              <Fragment key={message.id}>
                <Title level={3}>*Message</Title>
                <Text>
                  {formatMessage({ id: 'preHeader.maxMessageLength' })}
                </Text>
                <FormItem
                  className="webeat-preheader-section-hero-input"
                  colon={false}>
                  <div
                    style={{
                      width: '50%',
                      border: messageErrorLength(message.message).error
                        ? '1px solid red'
                        : '',
                      padding: 10,
                    }}>
                    <WYSWYGComponent
                      title=""
                      height={400}
                      hiddenButtons={hiddenButonsWYSWYGComponent}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(role.customerRole, role.active, {
                          ...role,
                          preHeaderMessages: (role.preHeaderMessages ?? []).map(
                            (m: IPreHeaderRoleMessage) => {
                              if (m.id === message.id) {
                                message.message = e.target.value;
                              }
                              return m;
                            },
                          ),
                        });
                      }}
                      value={message.message || ''}
                    />
                  </div>
                </FormItem>
                <br />
                <Title level={3}>*Select Icon </Title>
                <div
                  style={{
                    width: '50%',
                  }}>
                  <PreHeaderSVGSelector
                    imagesData={icons}
                    value={message.icon || ''}
                    handleChange={handleChange}
                    role={role}
                    message={message}
                  />
                </div>
                <br />
                <br />
                <br />
              </Fragment>
            </Panel>
          </Collapse>
        </div>
      ))}
      <Modal
        visible={isModalVisible}
        footer={false}
        onCancel={handleModalCancel}
        title={'Last active message'}>
        <p>
          {
            'The last active message cannot be deactivated when the role is active'
          }
        </p>
      </Modal>
    </div>
  );
};
