import React, { FC } from 'react';
import { Button, Input, Modal } from 'antd';

import { useIntl } from 'react-intl';
import '../../WebeatPreHeaderRoles.css';
import PreHeaderSVGSelectorItem from './PreHeaderSVGSelectorItem';

import { IPreHeaderRole } from '../../models/preheader.models';
import { IPreHeaderRoleMessage } from '../../models/preheader.models';
import usePreHeaderSVGSelector from '../../hooks/usePreHeaderSVGSelector';

export const PreHeaderSVGSelector: FC<{
  imagesData: any;
  value: string;
  handleChange: any;
  role: IPreHeaderRole;
  message: IPreHeaderRoleMessage;
}> = ({ imagesData, value, handleChange, role, message }) => {
  const {
    selectSVG,
    setOpen,
    handleSearch,
    open,
    modifiedSVGs,
    tmpSelectSVG,
    setTmpSelectSvg,
    handleClear,
    handleCancel,
    handleOk,
  } = usePreHeaderSVGSelector({
    value,
    handleChange,
    imagesData,
    role,
    message,
  });

  const { formatMessage } = useIntl();

  return (
    <>
      <Button
        type="primary"
        value={selectSVG ?? value}
        onClick={() => {
          setOpen(true);
          setTmpSelectSvg(null);
        }}
        className="webeat-svg-selector__button">
        {selectSVG ?? 'Select SVG'}
      </Button>
      <Modal
        title={
          <Input.Search
            placeholder={selectSVG ?? 'Search SVG'}
            name="search"
            onChange={handleSearch}
          />
        }
        className="webeat-svg-selector__modal"
        centered
        bodyStyle={{ overflowX: 'scroll' }}
        closable={false}
        visible={open}
        footer={[
          <div className="webeat-svg-selector-container__modal">
            <Button key="Clear" onClick={() => handleClear()}>
              {formatMessage({ id: 'userMenu.modal.button.clear' })}
            </Button>
            <div>
              <Button key="cancel" onClick={handleCancel}>
                {formatMessage({ id: 'userMenu.modal.button.cancel' })}
              </Button>

              <Button key="ok" type="primary" onClick={() => handleOk()}>
                {formatMessage({ id: 'userMenu.modal.button.ok' })}
              </Button>
            </div>
          </div>,
        ]}
        onCancel={() => {
          setOpen(false);
        }}>
        <div className="webeat-svg-selector__container">
          {modifiedSVGs.map((i: { svg: string; value: string }) => (
            <PreHeaderSVGSelectorItem
              item={i}
              setSelectSVG={setTmpSelectSvg}
              selectSVG={selectSVG}
              selectTempSvg={tmpSelectSVG}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};
