import React, { Fragment } from 'react';
import { InjectedIntlProps } from 'react-intl';
import Collapsible from './Components/Collapsible';
import { ComboData } from '../combos/ComboInterfaces';
import { IRow } from '../app/AppInterfaces';
import WebeatChallengeOptionsForm from './WebeatChallengeOptionsForm';

export interface IWebeatChallengeOptions {
  values: { [key: string]: any };
  intl: typeof InjectedIntlProps;
  editingResource: boolean;
  handleEditComponent: (component: string, value: boolean) => void;
  handleChangeField: ({
    id,
    value,
    type,
    multiSelectId,
  }: {
    id: string;
    value: any;
    type: string;
    multiSelectId?: string;
  }) => void;
  UploadImage: (key: string) => JSX.Element;
  UploadCoverVideo: (options: { key: string, label: string }) => JSX.Element;
  formHasChanged: boolean;
  brandCombo: ComboData[];
  roleTypeCombo: ComboData[];
  challengeTagCombo: ComboData[];
  getMultiSelectValues: ({
    id,
    multiSelectId,
    values,
    value,
  }: {
    id: string;
    multiSelectId: string;
    values: IRow;
    value: any;
  }) => {
    [x: string]: any;
  };
  isAnnualCampaign: boolean;
  isExperience: boolean;
  isExperienceProduct: boolean;
}

export default function WebeatChallengeConfig(props: IWebeatChallengeOptions) {
  const {
    intl: { formatMessage },
  } = props;

  return (
    <Collapsible
      title={formatMessage({ id: 'challenge.config' })}
      collapsible={false}
      defaultCollapsed={false}
    >
      <Fragment>
        <WebeatChallengeOptionsForm {...props} />
      </Fragment>
    </Collapsible>
  );
}
