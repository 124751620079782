import { Button, Col, Icon, Tooltip } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { FC, Fragment } from 'react';
import { InjectedIntlProps, useIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { IRow } from '../../../app/AppInterfaces';
import { ComboData } from '../../../combos/ComboInterfaces';
import { ReducersState } from '../../../reducers';
import { typesOfSelectEnum } from '../../../shared';
import {
  isConPlatform,
  isWebeatPlatform,
  loadItem,
  loadTimezoneName,
  PLATFORM_CODE_KEY,
} from '../../../utils';
import { SetQuiz } from '../../challengeActions';
import {
  IndexedDetail,
  IResource,
  IResourceDetail,
} from '../../ChallengeInterfaces';
import {
  DetailsTypesEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from '../../Enums';
import { SURVEY_BUTTON_LIMIT } from '../Resource';
import {
  getBaseValue,
  getDetailByType,
  getDetailFieldKey,
  getDetailIndexByType,
  isUnnecessaryMapsUrl,
  isUnnecessaryTimeToShow,
} from '../Resource.utils';
import {
  getDetailInitialValue,
  getDetailLabel,
  getDetailValidations,
} from '../ResourceDetails.utils';
import {
  TreeCheckbox,
  TreeDate,
  TreeInput,
  TreeNumber,
  TreeNumberPointsCheckoutPayment,
  TreeSelector,
  ExperienceProductSelector,
  ExperienceProductPrice,
  TreeTextarea,
  TreeUpload,
  TreeWYSWYG,
  WatchAgainField,
  CheckoutProductEAN,
  // LearningQuizQuestionTypeSelector,
} from './TreeDetailsFields';
import config from '../../../config';

const {
  BOOLEAN,
  CHALLENGE_TYPE,
  CHALLENGE,
  CHECKOUT_ADDRESS_TYPE,
  CHECKOUT_PRODUCT_TYPE,
  CLAUSE,
  DATE,
  ICON_TYPE,
  IMAGE,
  MAIL,
  MATERIAL_TYPE,
  NUMBER,
  PRODUCT: PRODUCT_TYPE,
  QUESTION_TYPE,
  SHOPPING_CART_TYPE,
  TEXT_AREA,
  TEXT,
  UPLOAD,
  URL,
  VIDEO_SECTION_TYPE,
  WYSWYG,
  EXTERNAL_PLATFORM,
  EXTERNAL_CERTIFIED_COURSES,
  REQUEST_TYPES_TYPE,
  EXTERNAL_REDEEM_POINTS_TYPE,
  WEBEAT_CHECKOUT_PRODUCT_TYPE,
  WEBEAT_CHECKOUT_PRODUCT_PRICE,
  // LEARNING_QUIZ_QUESTION_TYPE,
} = DetailsTypesEnum;

const {
  ANSWER,
  QUESTION,
  QUIZ,
  QUIZ_DERMO,
  TEXT_LINK_ITEM,
  SHOPPING_CART: CART,
  SHOPPING_CART_GROUP: CART_GROUP,
  SHOPPING_CART_PRODUCT: PRODUCT,
  STEPS_CONTENT,
  WEBEAT_LEARNING_QUIZ,
  WEBEAT_LEARNING_QUIZ_QUESTION,
  WEBEAT_LEARNING_QUIZ_ANSWER,
} = ResourceTypeEnum;

const {
  EVENT_END,
  EVENT_START,
  MATERIAL_CONTENT,
  MATERIAL_TYPE: MATERIAL_TYPE_DETAIL,
  SURVEY_BUTTON_TEXT,
  ARN_SURVEY_BUTTON_TEXT,
  WATCH_AGAIN_ONLY_ATTENDED_USERS,
  WATCH_AGAIN_YOUTUBE_ID,
  LEGAL_CHECK_URL,
  SHOPPING_CART_SHOW_PRICES,
  TEXT_LINK_TEXT,
  LEARNING_QUIZ_REPEATABLE,
  CHECKOUT_PRODUCT_EAN,
} = ResourceTypeDetailEnum;

interface IDetailProps {
  handleDeleteDetail?: (detailArrayPosition: number) => void;
  handleSelectedType?: (type: number) => void;
  comboChallengeByType?: any;
  accessToken?: string;
  childArrayPosition?: number;
  combo?: ComboData[];
  resourceList?: IResource[];
  detail: IResourceDetail;
  detailArrayPosition?: number;
  editRecursiveResource: ({
    value,
    parentArrayPosition,
    childArrayPosition,
    detailArrayPosition,
  }: {
    value: any;
    parentArrayPosition?: number;
    childArrayPosition?: number;
    detailArrayPosition?: number;
  }) => (dispatch: Dispatch<SetQuiz>, getState: () => ReducersState) => void;
  editResource: boolean;
  form: WrappedFormUtils<any>;
  groupDetails?: IndexedDetail[];
  handleDeleteImages?: (image: string, idResourceD: number) => void;
  mainIdResource: number;
  resource: IResource;
  parentArrayPosition?: number;
  values?: IRow;
  intl?: typeof InjectedIntlProps;
  maxLength?: number;
}

const RenderDetail: FC<IDetailProps> = ({
  handleDeleteDetail,
  handleSelectedType,
  comboChallengeByType,
  accessToken,
  childArrayPosition,
  combo,
  resourceList,
  detail,
  detailArrayPosition,
  editRecursiveResource,
  editResource,
  form,
  groupDetails,
  handleDeleteImages,
  mainIdResource,
  resource,
  parentArrayPosition,
  values,
  maxLength,
}) => {
  const intl = useIntl();

  const { formatMessage } = useIntl();
  const { idResourceD, idResourceTypeD: resourceDetailType, value } = detail;
  const {
    type: detailType,
    value: detailTypeValue,
    idResourceTypeD,
  } = resourceDetailType;

  const { idResourceType } = resource.idResourceType;

  const isShopping = [CART, CART_GROUP, PRODUCT].includes(idResourceType);
  const isQuiz = [
    ANSWER,
    QUESTION,
    QUIZ,
    QUIZ_DERMO,
    WEBEAT_LEARNING_QUIZ,
    WEBEAT_LEARNING_QUIZ_QUESTION,
    WEBEAT_LEARNING_QUIZ_ANSWER,
  ].includes(idResourceType);
  const showInputFrame = !isQuiz;
  let colSpan = 24;

  const challengeValues = values || {};

  let isFormComponent = true;

  let component: JSX.Element | null = null;
  const inputKey = getDetailFieldKey(
    mainIdResource,
    detailArrayPosition,
    parentArrayPosition,
    childArrayPosition,
  );

  const currentTimezone = loadTimezoneName();
  switch (detailType) {
    case TEXT:
    case URL:
      colSpan = idResourceTypeD === LEGAL_CHECK_URL ? 24 : 12;

      switch (true) {
        case idResourceTypeD === WATCH_AGAIN_YOUTUBE_ID:
          isFormComponent = false;

          const textData = getDetailByType(WATCH_AGAIN_YOUTUBE_ID, resource);
          const checkData = getDetailByType(
            WATCH_AGAIN_ONLY_ATTENDED_USERS,
            resource,
          );
          const checkArrayPosition = getDetailIndexByType(
            WATCH_AGAIN_ONLY_ATTENDED_USERS,
            resource,
          );

          const _endDateDetail = getDetailByType(EVENT_END, resource);

          const detailEndDate = moment(_endDateDetail?.value)
            .tz(currentTimezone)
            .utc();
          const nowDate = moment().tz(currentTimezone).utc();

          const isEventEnded = moment(detailEndDate).isBefore(nowDate);
          component = WatchAgainField({
            checkArrayPosition,
            checkData,
            childArrayPosition,
            detailArrayPosition,
            detailTypeValue,
            editRecursiveResource,
            editResource,
            idResourceD,
            isEnabled: editResource && (isEventEnded || Boolean(value?.trim())),
            parentArrayPosition,
            textData,
          });
          break;
        case isUnnecessaryMapsUrl(resource, idResourceTypeD):
          break;
        case idResourceTypeD === CHECKOUT_PRODUCT_EAN && isConPlatform():
          const platformCode =
            loadItem(PLATFORM_CODE_KEY) || config.APP.PLATFORM_CODE;
          const isConFrance = platformCode === 'con-fr';
          const onlyNumbers = !isConFrance;

          component = CheckoutProductEAN({
            detail,
            editRecursiveResource,
            editResource,
            form,
            formatMessage,
            idResourceD,
            onlyNumbers,
            childArrayPosition,
            detailArrayPosition,
            parentArrayPosition,
          });
          break;
        default:
          component = TreeInput({
            childArrayPosition,
            detailArrayPosition,
            editRecursiveResource,
            editResource,
            idResourceD,
            parentArrayPosition,
            limit:
              idResourceTypeD === SURVEY_BUTTON_TEXT ||
              idResourceTypeD === ARN_SURVEY_BUTTON_TEXT
                ? SURVEY_BUTTON_LIMIT
                : undefined,
          });
          break;
      }
      break;
    case TEXT_AREA:
      component = TreeTextarea({
        parentArrayPosition,
        childArrayPosition,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        maxLength,
      });
      break;
    case BOOLEAN:
      let isRepeatable;
      if (idResourceTypeD === WATCH_AGAIN_ONLY_ATTENDED_USERS) break;
      if (idResourceTypeD === LEARNING_QUIZ_REPEATABLE) break;

      isFormComponent = false;

      colSpan = [SHOPPING_CART_SHOW_PRICES].includes(idResourceTypeD)
        ? 12
        : colSpan;

      component = TreeCheckbox({
        childArrayPosition,
        detailArrayPosition,
        editRecursiveResource,
        editResource: isRepeatable ? false : editResource,
        idResourceD,
        parentArrayPosition,
        title: intl.formatMessage({
          id: `tree-resource.boolean-details.${detailTypeValue
            .toLowerCase()
            .replaceAll(' ', '_')}`,
        }),
        value,
      });
      break;
    case CHALLENGE:
      if (!comboChallengeByType) break;
      component = TreeSelector({
        childArrayPosition,
        combo: comboChallengeByType,
        detail,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        parentArrayPosition,
        value,
      });
      break;
    case CHALLENGE_TYPE:
      if (!combo) break;
      const challengesIds = resource.resourceDetailList?.filter(
        ({ idResourceTypeD: { idResourceTypeD }, value, status }) =>
          value &&
          status &&
          idResourceTypeD === ResourceTypeDetailEnum.LINKED_CHALLENGE_ID,
      );
      let isEnable = challengesIds
        ? challengesIds.length === 0 && editResource
        : editResource;

      component = TreeSelector({
        handleSelectedType,
        childArrayPosition,
        combo,
        detail,
        detailArrayPosition,
        editRecursiveResource,
        editResource: isEnable,
        idResourceD,
        parentArrayPosition,
        value,
      });
      break;
    case CHECKOUT_ADDRESS_TYPE:
    case CHECKOUT_PRODUCT_TYPE:
    case CLAUSE:
    case ICON_TYPE:
    case MAIL:
    case PRODUCT_TYPE:
    case QUESTION_TYPE:
    case VIDEO_SECTION_TYPE:
    case EXTERNAL_PLATFORM:
    case EXTERNAL_CERTIFIED_COURSES:
    case SHOPPING_CART_TYPE:
    case REQUEST_TYPES_TYPE:
    case EXTERNAL_REDEEM_POINTS_TYPE:
      if (!combo) break;

      if (
        [
          CHECKOUT_ADDRESS_TYPE,
          CHECKOUT_PRODUCT_TYPE,
          CLAUSE,
          SHOPPING_CART_TYPE,
          MAIL,
          VIDEO_SECTION_TYPE,
          ICON_TYPE,
          EXTERNAL_PLATFORM,
          EXTERNAL_CERTIFIED_COURSES,
          REQUEST_TYPES_TYPE,
          EXTERNAL_REDEEM_POINTS_TYPE,
        ].includes(detailType)
      ) {
        colSpan = 12;
      }

      component = TreeSelector({
        childArrayPosition,
        combo,
        detail,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        parentArrayPosition,
        value,
      });
      break;
    case WEBEAT_CHECKOUT_PRODUCT_TYPE:
      colSpan = 12;
      component = ExperienceProductSelector({
        resource,
        childArrayPosition,
        detail,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        parentArrayPosition,
        value,
      });
      break;
    // case LEARNING_QUIZ_QUESTION_TYPE:
    //   if (!combo) break;
    //   colSpan = 24;
    //   component = LearningQuizQuestionTypeSelector({
    //     childArrayPosition,
    //     combo,
    //     detail,
    //     detailArrayPosition,
    //     editRecursiveResource,
    //     editResource,
    //     idResourceD,
    //     parentArrayPosition,
    //     value,
    //   });
    //   break;
    case WEBEAT_CHECKOUT_PRODUCT_PRICE:
      colSpan = 12;
      component = ExperienceProductPrice({
        resource,
        childArrayPosition,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        parentArrayPosition,
        value,
        detailTypeValue,
      });
      break;
    case NUMBER:
      colSpan = 12;
      if (isShopping) colSpan = 6;
      if (isUnnecessaryTimeToShow(resource, idResourceTypeD)) break;
      if (detail.idResourceTypeD.value === 'LEARNING_QUIZ_MAX_REPETITIONS')
        break;

      //! RETURN ONLY ELSE WHEN THE CHECKOUT PAYMENT POINTS HAVE BEEN REFACTORED
      if (
        detail.idResourceTypeD.value ===
        'CHECKOUT_PAYMENT_GATEWAY_PRODUCT_POINTS'
      ) {
        component = TreeNumberPointsCheckoutPayment({
          childArrayPosition,
          detailArrayPosition,
          editRecursiveResource,
          editResource,
          idResourceD,
          parentArrayPosition,
        });
      } else if (
        detail.idResourceTypeD.value ===
        'WEBEAT_CHECKOUT_PRODUCT_STOCK_CONSUMED'
      ) {
        component = TreeNumber({
          childArrayPosition,
          detailArrayPosition,
          editRecursiveResource,
          editResource: false,
          idResourceD,
          parentArrayPosition,
        });
      } else {
        component = TreeNumber({
          childArrayPosition,
          detailArrayPosition,
          editRecursiveResource,
          editResource,
          idResourceD,
          parentArrayPosition,
        });
      }
      break;
    case DATE:
      let isDateEnabled = editResource;
      if ([EVENT_END, EVENT_START].includes(idResourceTypeD) && isDateEnabled)
        isDateEnabled = values?.startDate && values?.endDate;

      colSpan = 12;
      component = TreeDate({
        childArrayPosition,
        detailArrayPosition,
        editRecursiveResource,
        editResource: isDateEnabled,
        idResourceD,
        parentArrayPosition,
        value,
      });
      break;
    case WYSWYG:
      let wysiwygTitle = getDetailLabel({
        isBoolean: false,
        idResourceTypeD,
        detailTypeValue,
        formatMessage: intl.formatMessage,
      });
      wysiwygTitle = wysiwygTitle ?? detailTypeValue;
      isFormComponent =
        isWebeatPlatform() && [STEPS_CONTENT].includes(idResourceType);
      component = TreeWYSWYG({
        childArrayPosition,
        detailArrayPosition,
        editRecursiveResource,
        editResource,
        idResourceD,
        parentArrayPosition,
        title: isFormComponent ? undefined : wysiwygTitle,
        value,
      });
      break;
    case UPLOAD:
    case IMAGE:
      colSpan = 12;
      let editRecursiveResourceMaterialType: any;
      let materialTypeValue: typesOfSelectEnum | undefined = undefined;
      // TODO - This is only valid for Challenge Materials
      // TODO - Extract to a utils
      if (idResourceTypeD === MATERIAL_CONTENT) {
        const DEFAULT_MATERIAL = typesOfSelectEnum.VIDEO;
        const materialType = groupDetails?.find(
          ({
            detail: {
              status,
              idResourceTypeD: { idResourceTypeD },
            },
          }) => status && idResourceTypeD === MATERIAL_TYPE_DETAIL,
        );

        if (!materialType) break;

        editRecursiveResourceMaterialType = (value: string) => {
          editRecursiveResource({
            value,
            detailArrayPosition: materialType.index,
            parentArrayPosition,
          });
        };
        materialTypeValue =
          parseInt(materialType.detail.value) || DEFAULT_MATERIAL;
      }

      component = TreeUpload({
        accessToken,
        childArrayPosition,
        detailArrayPosition,
        detailType,
        editRecursiveResource,
        editResource,
        handleDeleteImages: handleDeleteImages
          ? function (image: string, idResourceD: number) {
              editRecursiveResource({
                value: null,
                parentArrayPosition,
                detailArrayPosition,
                childArrayPosition,
              });
              handleDeleteImages(image, idResourceD);
            }
          : null,
        idResourceD,
        idResourceTypeD,
        materialType: materialTypeValue,
        parentArrayPosition,
        setValue: function (
          value?: string,
          fileType?: ResourceTypeEnum | typesOfSelectEnum,
        ) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            [inputKey]: value,
          });
          editRecursiveResource({
            value,
            parentArrayPosition,
            detailArrayPosition,
            childArrayPosition,
          });
          if (materialTypeValue) editRecursiveResourceMaterialType(fileType);
        },
        title: detailTypeValue,
        value,
        idChallenge: values?.idChallenge,
        form,
      });
      break;
    case MATERIAL_TYPE:
    default:
      break;
  }

  if (!component) return <Fragment key={detailArrayPosition} />;

  const textLinkTooltip =
    idResourceTypeD === TEXT_LINK_TEXT && isWebeatPlatform() ? (
      <Tooltip
        className="fieldTooltip"
        title={formatMessage({
          id: `component-type.text_link_input_msg`,
        })}>
        <Icon type="question-circle-o" />
      </Tooltip>
    ) : (
      <Fragment />
    );

  return (
    <Col key={detailArrayPosition} xs={24} md={colSpan}>
      {detailType === CHALLENGE && (
        <Button
          className="deleteButton buttonNoFrame"
          disabled={!editResource}
          icon="minus-circle"
          onClick={() => {
            handleDeleteDetail &&
              detailArrayPosition &&
              handleDeleteDetail(detailArrayPosition);
          }}
        />
      )}

      {isFormComponent &&
      component.type !== TEXT_LINK_ITEM &&
      component.props.className !== WEBEAT_CHECKOUT_PRODUCT_PRICE ? (
        <Form.Item
          label={formatMessage({
            id: `tree-resource.${detailTypeValue?.toLowerCase()}`,
          })}
          className="Quiz__tree-input">
          {editResource || showInputFrame
            ? form.getFieldDecorator(inputKey, {
                initialValue: getDetailInitialValue(
                  detail.idResourceTypeD,
                  value,
                ),
                rules: getDetailValidations(
                  resourceDetailType,
                  challengeValues,
                  resource,
                ),
              })(component)
            : getBaseValue(value, combo)}
          {textLinkTooltip}
        </Form.Item>
      ) : (
        component
      )}
    </Col>
  );
};

export default RenderDetail;
