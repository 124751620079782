export enum UploadType {
  /**
   * Videos uploaded to S3. Saved as a relative path relative to the S3 bucket.
   */
  S3 = 's3',
  /**
   * Generic videos represented by an absolute URL to the place where they are
   * available.
   *
   * Examples: URLs to files stored in the DAM or other websites, YouTube URLs, etc.
   */
  GenericUrl = 'url',
}

export type UploadTypeConfig = {
  /** Ant Design icon to represent this upload type. */
  icon: string;
  /** List of valid mime types to validate when uploading a video. */
  validMimeTypes?: string[];
};

export const uploadTypesConfiguration: Record<UploadType, UploadTypeConfig> = {
  [UploadType.S3]: {
    icon: 'video-camera',
    validMimeTypes: ['video/mpeg', 'video/mp4', 'video/webm'],
  },
  [UploadType.GenericUrl]: {
    icon: 'link',
  },
};

/**
 * Infers the upload type from a given URL.
 *
 * Since we only save the URL and not its type in the cover video db fields, this
 * lets us infer the type of saved URLs.
 */
export function getUploadType(url: string): UploadType | null {
  if (isGenericUrl(url)) {
    return UploadType.GenericUrl;
  } else if (isS3Video(url)) {
    return UploadType.S3;
  } else {
    return null;
  }
}

export function validateVideoUrl(url: string, type: UploadType): boolean {
  switch (type) {
    case UploadType.S3:
      return isS3Video(url);

    case UploadType.GenericUrl:
      return isGenericUrl(url);
  }
}

const ALLOWED_S3_FILE_EXTENSIONS = ['mpeg', 'mp4', 'webm'];
function isS3Video(url: string): boolean {
  if (url.startsWith('https://')) {
    return false;
  }

  const extensionIndex = url.lastIndexOf('.');
  if (extensionIndex === -1) {
    return false;
  }

  const extension = url.slice(extensionIndex + 1);
  return ALLOWED_S3_FILE_EXTENSIONS.includes(extension);
}

function isGenericUrl(url: string): boolean {
  return url.startsWith('https://');
}

export function getValidMimeTypes(type: UploadType): string[] | null {
  return uploadTypesConfiguration[type]?.validMimeTypes ?? null;
}
