import { IJodit, Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { INVALID_CHAR_REGEX } from '../../utils/stringUtils';

interface PageWYSProps {
  title?: string;
  value: string;
  readonly?: boolean;
  height?: number;
  handleEdit?: (value: string) => void; // Deprecated
  onChange?: ChangeEventHandler;
  hiddenButtons?: string[];
}

const WYSWYGComponent = ({
  handleEdit,
  onChange,
  readonly,
  title,
  value,
  hiddenButtons,
}: PageWYSProps) => {
  const { formatMessage } = useIntl();
  const textArea = useRef<any>(null);
  const previousValue = useRef(`${value}`);

  const raiseChangeEvent = (newValue: string) => {
    if (
      typeof newValue === 'string' &&
      newValue.length > 0 &&
      newValue.match(INVALID_CHAR_REGEX)
    ) {
      newValue = newValue.replace(INVALID_CHAR_REGEX, ''); // Remove "not-valid or strange" characters
      textArea.current.value = newValue; // Update the value after edit
    }
    if (handleEdit) {
      console.warn(
        'WYSWYGComponent - "handleEdit" prop is deprecated use "onChange" for compatibility with antd.',
      );
      handleEdit(newValue);
    }
    onChange &&
      onChange({ target: { value: newValue } } as unknown as ChangeEvent);
  };

  useLayoutEffect(() => {
    const _textArea = Jodit.make(textArea.current || '#wyswygCcomponent', {
      controls: {
        font: {
          list: {
            'Mulish,sans-serif': 'Mulish',
          },
        },
      },
      cleanHTML: {
        fillEmptyParagraph: false,
      },
      readonly,
      removeButtons: ['about', 'dots', ...(hiddenButtons || [])],

      extraButtons: [
        {
          name: 'source',
          tabIndex: 0,
          icon: 'save',
          exec: function (editor: IJodit) {
            const value = editor.currentPlace.editor.innerHTML;
            raiseChangeEvent(value);
          },
        },
      ],
    });

    _textArea.events.on('change', function (newValue: string) {
      const currentValue = previousValue.current;
      if (newValue !== currentValue) {
        raiseChangeEvent(newValue);
        previousValue.current = newValue;
      }
    });

    _textArea.events.on('blur', (event: FocusEvent) => {
      // @ts-ignore
      const { innerHTML } = event.target;
      raiseChangeEvent(innerHTML);
    });

    textArea.current = _textArea;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textArea]);

  useEffect(() => {
    const element = textArea.current;

    return () => {
      element && element.destruct();
      textArea.current = element;
    };
  }, []);

  useEffect(() => {
    if (textArea.current && textArea.current.value !== value) {
      textArea.current.value = value;
      textArea.current.setEditorValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (value !== previousValue.current) {
      previousValue.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (textArea?.current?.readonly !== readonly) {
      textArea.current.setReadOnly(readonly);
      textArea.current.setDisabled(readonly);
    }
  }, [readonly]);

  return (
    <>
      <div className="ChallengeBuilder__leftCol--longDescription">
        {title ?? formatMessage({ id: 'challenge.descriptionLong' })}
      </div>
      <div style={{ clear: 'both' }} />
      <textarea ref={textArea} />
    </>
  );
};

export default WYSWYGComponent;
