import { Modal, Row, Progress } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import './UploadingModal.css';

interface Props {
  uploading: boolean;
}

export default function UploadingModal({ uploading }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      visible={uploading}
      closable={false}
      footer={false}
      className="webeatCoverVideoUploadingModal"
    >
      <Row
        type="flex"
        align="middle"
        className="webeatCoverVideoUploadingModal__row"
      >
        <Row className="webeatCoverVideoUploadingModal__container">
          <div className="webeatCoverVideoUploadingModal__title">
            {formatMessage({
              id: 'webeat-cover-video-upload.s3-input.uploading',
            })}
          </div>
          <Progress percent={100} status="active" />
        </Row>
      </Row>
    </Modal>
  );
}
